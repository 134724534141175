/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable camelcase */
import Fuse from 'fuse.js';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import axios from 'axios';
import getConfig from 'next/config';
import moment from 'moment';
import cookie from 'js-cookie';
import type { AppDispatch } from '../../store';

const { publicRuntimeConfig } = getConfig();

interface UnpaidCustomerData {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  solvent: boolean;
  exportRecogest: boolean;
}

interface unpaidInvoices {
  transactionId: number;
  reference: string;
  paymentMean: string;
  missionDate: string;
  lastAttemptDate: string;
  attemptNumber: number;
  amount: number;
  comment: string;
  resolved: boolean;
}
interface UnpaidData {
  customer: UnpaidCustomerData;
  unpaidInvoices: unpaidInvoices[];
}

interface UnpaidDataParsed {
  hasSelected: boolean;
  customer: UnpaidCustomerData & { lastMissonDate: string | null; totalAmount: number | null };
  unpaidInvoices: unpaidInvoices[];
}

interface UnpaidStatsData {
  unpaidInvoices: number;
  unpaidAmount: number;
  customerNotSolvent: number;
  customerSentToRecogest: number;
}

interface FilterNavElements {
  name: string;
  value: string;
  selected: boolean;
}

interface InitState {
  startDate: string | null;
  endDate: string | null;
  search: string;
  isLoading: boolean;
  filteredBy: string;
  filterNavElements: FilterNavElements[];
  unpaidStatsData: UnpaidStatsData | null;
  unpaidData: UnpaidDataParsed[];
  unpaidDefaultData: UnpaidDataParsed[];
  errMessageFetchUnpaidData: string;
  errMessageFetchUnpaidStatsData: string;
  errMessagePostAction: string;
}

const initialState: InitState = {
  startDate: moment().startOf('month').format('YYYY-MM-DD'),
  endDate: moment().endOf('month').format('YYYY-MM-DD'),
  search: '',
  isLoading: false,
  filteredBy: 'unpaidInProgress',
  filterNavElements: [
    { name: 'Impayés en cours', value: 'unpaidInProgress', selected: true },
    { name: 'Impayés résolus', value: 'unpaidResolved', selected: false },
    { name: 'Insovable', value: 'insolvent', selected: false },
    { name: 'Recogest', value: 'recogest', selected: false },
  ],
  unpaidStatsData: null,
  unpaidData: null,
  unpaidDefaultData: null,
  errMessageFetchUnpaidData: '',
  errMessageFetchUnpaidStatsData: '',
  errMessagePostAction: '',
};

const getUnpaidInProgress = (unpaidData: UnpaidDataParsed[]): UnpaidDataParsed[] => unpaidData
  .map((data) => {
    const unpaidInvoicesFiltered = data.unpaidInvoices.filter((invoice) => !invoice.resolved);

    return {
      ...data,
      unpaidInvoices: unpaidInvoicesFiltered,
    };
  })
  .filter((element) => element.unpaidInvoices.length > 0);

const getUnpaidResolved = (unpaidData: UnpaidDataParsed[]): UnpaidDataParsed[] => unpaidData
  .map((data) => {
    const unpaidInvoicesFiltered = data.unpaidInvoices.filter((invoice) => invoice.resolved);

    return {
      ...data,
      unpaidInvoices: unpaidInvoicesFiltered,
    };
  })
  .filter((element) => element.unpaidInvoices.length > 0);

const getCustomerInsolvent = (unpaidData: UnpaidDataParsed[]): UnpaidDataParsed[] => unpaidData?.filter((data) => !data.customer.solvent);

const getUnpaidRecogest = (unpaidData: UnpaidDataParsed[]): UnpaidDataParsed[] => unpaidData?.filter((data) => data.customer.exportRecogest);

const filterData = {
  unpaidInProgress: getUnpaidInProgress,
  unpaidResolved: getUnpaidResolved,
  insolvent: getCustomerInsolvent,
  recogest: getUnpaidRecogest,
};

const getParsedUnpaidData = (data: UnpaidData[]): UnpaidDataParsed[] => data?.map((element) => {
  const lastMissonDate: string = element.unpaidInvoices.reduce((a, b) => (moment(a.missionDate).isAfter(b.missionDate) ? a : b))?.missionDate;

  const totalAmount: number = element.unpaidInvoices.reduce(
    (accumulateur, valeurCourante) => accumulateur + +valeurCourante.amount,
    0,
  );

  return {
    customer: {
      ...element.customer,
      lastMissonDate: lastMissonDate || null,
      totalAmount: totalAmount ?? null,
    },
    hasSelected: false,
    unpaidInvoices: element.unpaidInvoices,
  };
});

const UnpaidSlice = createSlice({
  name: 'unpaid',
  initialState,
  reducers: {
    setState(state, action: PayloadAction<{ value: Date | string | null; name: string }>) {
      const { name, value } = action.payload;
      state[name] = value;
    },
    updatedShowUnpaidInvoice(state, action: PayloadAction<{ email: string }>) {
      const { email } = action.payload;

      state.unpaidData = state.unpaidData.map((element) => (element.customer.email === email
        ? { ...element, hasSelected: !element.hasSelected }
        : element));
    },
    updateSearchData(state, action: PayloadAction<{ value: string; name: string }>) {
      const { value, name } = action.payload;
      const unpaidData: UnpaidDataParsed[] = JSON.parse(JSON.stringify(state.unpaidDefaultData));

      const fuse = new Fuse(unpaidData, {
        keys: ['customer.firstName', 'customer.lastName', 'customer.email'],
      });

      const dataSearched = fuse.search(value).map((element) => element.item);

      state[name] = value;
      state.unpaidData = value === '' ? unpaidData : dataSearched;
    },
    updateIsLoading(state, action: PayloadAction<{ value: boolean }>) {
      const { value } = action.payload;
      state.isLoading = value;
    },
    filteringUnpaidData(state, action: PayloadAction<{ value: string }>) {
      const { value } = action.payload;
      const unpaidDefaultData: UnpaidDataParsed[] = JSON.parse(
        JSON.stringify(state.unpaidDefaultData),
      );
      const filterNavElements: FilterNavElements[] = JSON.parse(
        JSON.stringify(state.filterNavElements),
      );

      const filteredFunction: (unpaidData: UnpaidDataParsed[]) => UnpaidDataParsed[] = filterData[value];
      const parsedData = getParsedUnpaidData(filteredFunction(unpaidDefaultData));

      state.filteredBy = value;
      state.filterNavElements = filterNavElements.map((element) => (element.value === value ? { ...element, selected: true } : { ...element, selected: false }));
      state.unpaidData = parsedData;
    },
    resetErrMessagePostAction: (state) => {
      state.errMessagePostAction = '';
    },
  },
  extraReducers: {
    FETCH_UNPAID_STATS_DATA: (
      state,
      action: PayloadAction<{ unpaidStatsData: UnpaidStatsData | null }>,
    ) => {
      const { unpaidStatsData } = action.payload;
      state.unpaidStatsData = unpaidStatsData;
    },
    ERR_FETCH_UNPAID_STATS_DATA: (state, action: PayloadAction<{ status: number | null }>) => {
      const { status } = action.payload;

      state.unpaidStatsData = null;

      if (status === 401 || status === 422) {
        state.errMessageFetchUnpaidStatsData = "Une erreur s'est produite, veuillez vous reconnectez";
      } else if (status === 403) {
        state.errMessageFetchUnpaidStatsData = "Vous n'avez pas les droits pour accéder à ces données";
      } else {
        state.errMessageFetchUnpaidStatsData = "Une erreur s'est produite, veuillez réessayer plus tard";
      }
    },
    FETCH_UNPAID_DATA: (state, action: PayloadAction<{ unpaidData: UnpaidData[] }>) => {
      const { unpaidData } = action.payload;

      const filteredFunction: (data: UnpaidData[]) => UnpaidData[] = filterData[state.filteredBy];
      const parsedData = getParsedUnpaidData(filteredFunction(unpaidData));

      state.unpaidData = parsedData;
      state.unpaidDefaultData = parsedData;
      state.isLoading = false;
      state.errMessageFetchUnpaidData = '';
    },
    ERR_FETCH_UNPAID_DATA: (state, action: PayloadAction<{ status: number | null }>) => {
      const { status } = action.payload;

      state.unpaidData = null;
      state.unpaidDefaultData = null;
      state.isLoading = false;

      if (status === 401 || status === 422) {
        state.errMessageFetchUnpaidData = "Une erreur s'est produite, veuillez vous reconnectez";
      } else if (status === 403) {
        state.errMessageFetchUnpaidData = "Vous n'avez pas les droits pour accéder à ces données";
      } else {
        state.errMessageFetchUnpaidData = "Une erreur s'est produite, veuillez réessayer plus tard";
      }
    },
    ERR_POST_ACTION: (state, action: PayloadAction<{ status: number | null }>) => {
      const { status } = action.payload;

      if (status === 401 || status === 422) {
        state.errMessagePostAction = "Une erreur s'est produite, veuillez vous reconnectez";
      } else if (status === 403) {
        state.errMessagePostAction = "Vous n'avez pas les droits pour accéder à ces données";
      } else {
        state.errMessagePostAction = "Une erreur s'est produite, veuillez réessayer plus tard";
      }
    },
    [HYDRATE]: (state, action) => {
      const { unpaidReducer }: { unpaidReducer: InitState } = action.payload;
      return (state = unpaidReducer);
    },
  },
});

export const fetchUnpaidStatsData = (apiToken: string) => async (dispatch: AppDispatch): Promise<void> => {
  try {
    const response = await axios.get(
      `${publicRuntimeConfig.API_NOBO_URL}/manager/unpaid-invoices/stats`,
      {
        headers: {
          'Content-Type': 'application/json',
          apiToken,
        },
      },
    );

    const data = response?.data?.data as UnpaidStatsData;
    dispatch({ type: 'FETCH_UNPAID_STATS_DATA', payload: { unpaidStatsData: data } });
  } catch (err) {
    dispatch({
      type: 'ERR_FETCH_UNPAID_STATS_DATA',
      payload: { status: err?.response?.status || null },
    });
  }
};

export const fetchUnpaidData = (startDate: string, endDate: string, apiToken: string | null = null) => async (dispatch: AppDispatch): Promise<void> => {
  try {
    const response = await axios.get(
      `${publicRuntimeConfig.API_NOBO_URL}/manager/unpaid-invoices?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          'Content-Type': 'application/json',
          apiToken: apiToken || cookie.get('apiToken'),
        },
      },
    );

    const data = response?.data?.data as UnpaidData[];

    dispatch({ type: 'FETCH_UNPAID_DATA', payload: { unpaidData: data } });
  } catch (err) {
    dispatch({
      type: 'ERR_FETCH_UNPAID_DATA',
      payload: { status: err?.response?.status || null },
    });
  }
};

export const postAction = (path: string, body: { reason: string } | undefined) => async (dispatch: AppDispatch): Promise<boolean> => {
  try {
    const response = await axios.post(`${publicRuntimeConfig.API_NOBO_URL}${path}`, body, {
      headers: {
        'Content-Type': 'application/json',
        apiToken: cookie.get('apiToken'),
      },
    });

    return response?.data?.status === 'success';
  } catch (err) {
    dispatch({
      type: 'ERR_POST_ACTION',
      payload: { status: err?.response?.status || null },
    });
    return false;
  }
};

export const {
  setState,
  updatedShowUnpaidInvoice,
  updateSearchData,
  filteringUnpaidData,
  updateIsLoading,
  resetErrMessagePostAction,
} = UnpaidSlice.actions;

export default UnpaidSlice.reducer;
